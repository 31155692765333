<template>
    <div>
        <div v-if="sets && sets.length == 0">
            <v-btn block x-large @click="addAt(0)">Add a Question Set!</v-btn>
        </div>
        <v-stepper v-model="currentTabSet" id="stepper" class="mx-3 mt-0">
            <v-stepper-header>
                <v-stepper-step v-for="(set, ix) in sets" :key="'sets-' + ix + set.ID" :disabled="setIsHidden(set)" color="q_new_b_3" :complete="currentTabSet > (ix + 1)" :step="ix + 1" @click="nextStep(ix+1)" class="pointer">
                    <span>
                        {{set.FormQuestionSetTab}}
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small fab color="primary" dark v-bind="attrs" v-on="on">
                                    <v-icon left>fas fa-ellipsis-v</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item class="clickable" @click="editSet(ix)">
                                    <v-list-item-icon>
                                        <v-icon>fas fa-edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="ix != 0" class="clickable" @click="editShiftSet(ix, -1)">
                                    <v-list-item-icon>
                                        <v-icon>fas fa-angle-double-left</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Shift Left</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="ix < sets.length - 1" class="clickable" @click="editShiftSet(ix, 1)">
                                    <v-list-item-icon>
                                        <v-icon>fas fa-angle-double-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Shift Right</v-list-item-title>
                                </v-list-item>
                                <v-list-item class="clickable" @click="deleteSet(ix)">
                                    <v-list-item-icon>
                                        <v-icon>fas fa-times</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </span>
                </v-stepper-step>
                <v-stepper-step :key="'new-set'" color="q_new_b_3" step="+" @click="addAt(sets.length)" class="pointer">
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content v-for="(set, ix) in sets" :key="'sets-questions-' + ix + set.ID" :step="ix + 1">
                    <div :class="setIsHidden(set) ? 'disabled' : ''">
                        <h3 v-if="set.FormQuestionSetTitle">
                            <v-btn elevation="0" small @click="toggleCollapseBlocks()">
                                <v-icon small>fas {{collapseBlocksIcon()}}</v-icon>
                            </v-btn>
                            {{set.FormQuestionSetTitle}}
                        </h3>
                        <v-btn v-else elevation="0" small @click="toggleCollapseBlocks()">
                            <v-icon small>fas {{collapseBlocksIcon()}}</v-icon>
                        </v-btn>
                        <q-edit-questions v-model="set.FormQuestions" v-on:save="$emit('save')" :collapse-blocks="collapseBlocks"
                                          :form-type="formType"
                        ></q-edit-questions>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-dialog v-model="show_edit_question_set" width="60%">
            <v-card>
                <v-card-title>Edit Question Set</v-card-title>
                <v-card-text>
                    <v-text-field label="Title" v-model="sets[currentQuestionStepIx].FormQuestionSetTitle"></v-text-field>
                    <v-text-field label="Tab Label" v-model="sets[currentQuestionStepIx].FormQuestionSetTab"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="$emit('save')" color="primary">Save</v-btn>
                    <v-btn @click="show_edit_question_set = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import draggable from "vuedraggable";
/*
import AccordionEditor from './components/editors/AccordionEditor.vue'
import SpacerEditor from './components/editors/SpacerEditor.vue'
import FullWidthTextEditor from './components/editors/FullWidthTextEditor.vue'
import ZendeskTextEditor from './components/editors/ZendeskTextEditor.vue'
import TwoCol5050Editor from './components/editors/TwoCol5050Editor.vue'
import ButtonGroupEditor from './components/editors/ButtonGroupEditor.vue'
import CardGroupEditor from './components/editors/CardGroupEditor.vue'
import WistiaVideoGroupEditor from './components/editors/WistiaVideoGroupEditor.vue'
import IframeGroupEditor from './components/editors/IframeGroupEditor.vue'
import BannerEditor from './components/editors/BannerEditor.vue'
import LeaderboardEditor from './components/editors/LeaderboardEditor.vue'
import ScriptEmbedGroupEditor from './components/editors/ScriptEmbedGroupEditor.vue'
import TwoCol7525Editor from './components/editors/TwoCol7525Editor.vue'
import TwoCol2575Editor from './components/editors/TwoCol2575Editor.vue'
import CallToActionEditor from './components/editors/CallToActionEditor.vue'
import FourCol25252525Editor from './components/editors/FourCol25252525Editor.vue'
import ThreeCol333333Editor from './components/editors/ThreeCol333333Editor.vue'
import ThreeCol255025Editor from './components/editors/ThreeCol255025Editor.vue'
import CarrierContactInfoEditor from './components/editors/CarrierContactInfoEditor.vue'
import CarrierProductsEditor from './components/editors/CarrierProductsEditor.vue'
import AcknowledgeCheckBoxEditor from './components/editors/AcknowledgeCheckBoxEditor.vue'
import DataTableGroupEditor from './components/editors/DataTableGroupEditor.vue'
*/
import { EventBus } from '@/event-bus';
import Styles from '@/CMS/editor/components/Styles'
import ImageSelector from '@/CMS/editor/components/ImageSelector.vue'
import DateSelector from '@/CMS/editor/components/DateSelector.vue'
import QTabs from '../../components/navigation/QTabs.vue'
import QEditQuestions from './QEditQuestions.vue'

//import SummitCalculatorEditor from "@/CMS/editor/components/editors/SummitCalculatorEditor";

import moment from 'moment'
export default {
    name: "QEditFormSets",
    props: ['value', 'random', 'formType'],
    data() {
        return {
            mediaCallback: function() {},
            show_media: false, //shows the media library
            show_documents: false, // shows the document library
            dragging: false,
            counter: Math.floor(Math.random() * 1000000000),
            available_types: [{
                label: "Text Field",
                value: "fml-text-field"
            }],
            available_styles: Styles.BlockStyles,
            collapseBlocks: false,
            currentTabSet: 1,
            show_edit_question_set: false,
            currentQuestionStepIx: 0
        };
    },
    mounted: function() {
        var g = this
        EventBus.$on('medialibrary', function(callback) {
            g.show_media = true;
            g.mediaCallback = callback;
        });
        EventBus.$on('documentlibrary', function(callback) {
            g.show_documents = true;
            g.documentCallback = callback;
        });
    },
    beforeActivated() {

    },
    computed: {
        division_items: function() {
            return [
                'All',
                ...this.raw_divisions,
            ]
        },
        role_items: function() {
            return [
                'Any',
                ...this.role_choices,
            ]
        },
        permission_items: function() {
            return [
                'Any',
                ...this.permission_choices,
            ]
        },
        sets: function() {
            return this.value
        }
    },
    methods: {
        addAt(idx) {
            this.counter++;
            this.sets.splice(idx, 0, {
                ID: "new",
                key: "qs-" + this.counter,
                SortOrder: idx,
                FormQuestionSetTab: "Set " + (this.sets.length + 1),
                FormQuestionSetTitle: "Question Set #" + (this.sets.length + 1),
                FormQuestionSetConfig: {
                    styles: [],
                    cols: 12,
                    smCols: null,
                    mdCols: null,
                    lgCols: null,
                },
                FormQuestions: [{
                    ID: "new",
                    FormQuestionTitle: "My First Question?",
                    FormQuestionContent: null,
                    FormQuestionConfig: {
                        AnswerDataType: "String",
                        type: "text-field",
                        rules: [],
                        cols: 12,
                        smCols: null,
                        mdCols: null,
                        lgCols: null,
                        label: 'Answer',
                        messages: null,
                        hint: null,
                        prefix: null,
                        prependIcon: null,
                        default: null,
                        permissions: {},
                        useQuestionAsLabel: true
                    },
                }]
            });
        },
        deleteSet(idx) {
            this.sets.splice(idx, 1);
        },
        editSet(idx) {
            this.currentQuestionStepIx = idx
            this.show_edit_question_set = true;
        },
        editShiftSet(idx, direction) {
            this.array_move(this.sets, idx, idx + direction);
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },
        setIsHidden(set) {
            if (!set || typeof set.EnabledInd == 'undefined')
                return false
            return set.EnabledInd;
        },
        nextStep: function(s) {
            var g = this;
            this.$vuetify.goTo('#stepper', {
                    duration: 500,
                    easing: 'easeOutCubic',
                    offset: 300
                })
                .then(function(x) {
                    g.currentTabSet = s
                });
        },
        collapsedClasses: function(classes) {
            if (this.collapseBlocks)
                classes.push('collapsed')
            return classes.join(' ')
        },
        collapseBlocksIcon: function() {
            if (this.collapseBlocks)
                return "fa-chevron-right"
            return "fa-chevron-down"
        },
        toggleCollapseBlocks: function() {
            this.collapseBlocks = !this.collapseBlocks
        },
    },
    watch: {
        // 'page.blocks': {
        //     deep: true,
        //     handler: function(newV, oldV) {
        //         this.hasChanges = true;
        //     }
        // },
    },
    components: {
        draggable,
        QTabs,
        QEditQuestions,
        /*
        AccordionEditor,
        FullWidthTextEditor,
        SpacerEditor,
        TwoCol5050Editor,
        ButtonGroupEditor,
        CardGroupEditor,
        IframeGroupEditor,
        CallToActionEditor,
        FourCol25252525Editor,
        ThreeCol333333Editor,
        ThreeCol255025Editor,
        TwoCol2575Editor,
        TwoCol7525Editor,
        BannerEditor,
        LeaderboardEditor,
        ZendeskTextEditor,
        ImageSelector,
        DateSelector,
        ScriptEmbedGroupEditor,
        CarrierContactInfoEditor,
        CarrierProductsEditor,
        AcknowledgeCheckBoxEditor,
        WistiaVideoGroupEditor,
        DataTableGroupEditor,
        SummitCalculatorEditor,
        */
    },
}
</script>
<style scoped>
.set {
    position: relative;
}

.set .disabled {
    opacity: .25;
}

.fab-container {
    padding: 15px;
    background-color: #f7f7f7;
    padding-right: 60px;
}

.unpublished-block {
    color: rgba(0, 0, 0, 0.1) !important;
}

.fab-container.collapsed {
    position: relative;
}

.block-container.collapsed {
    display: none;
}
</style>
