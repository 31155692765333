import { render, staticRenderFns } from "./QFormDataTable.vue?vue&type=template&id=0b85dcbc&scoped=true&"
import script from "./QFormDataTable.vue?vue&type=script&lang=js&"
export * from "./QFormDataTable.vue?vue&type=script&lang=js&"
import style0 from "./QFormDataTable.vue?vue&type=style&index=0&id=0b85dcbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b85dcbc",
  null
  
)

export default component.exports