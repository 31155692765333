<template>
    <div style="display:inline-block;">
        <v-btn color="q_leads_2" dark @click="newFormDialog = true">
            <v-icon small left>fas fa-plus</v-icon> New Form
        </v-btn>
        <v-dialog v-model="newFormDialog" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <h4 class="pa-4 font-weight-bold">What type of form would you like to create?</h4>
                <v-select :items="formTypes" v-model="formType">
                </v-select>
                <v-btn color="primary" @click="newPage">
                    Get started!<v-icon small right>fas fa-plus</v-icon>
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Page from '@/store/Models/Page'
import Category from '@/store/Models/Category'

export default {
    props: ['formType'],
    name: "NewFormButton",
    data: function() { // data internal to component (not available outside)
        return {
            newFormDialog: false,
            formTypes: [
                { text: "Simple", value: 'simple' },
                { text: "Quiz", value: 'Quiz' },
                //{ text: "Tabbed", value: 'tabbed' },
                //{ text: "Accordion", value: 'accordion' },
            ]
        }
    },
    computed: {

    },
    methods: {
        newPage: function() {
            this.$router.push({ name: 'FMLEditor', params: { FormID: 'new', form_type: this.formType } })
        },

    },
    watch: {

    },
    components: {}
}
</script>