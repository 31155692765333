<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <div class="text-center pt-10 pr-12">
            <v-btn @click="getDocument">Reload</v-btn>
        </div>
        <v-dialog class="test-center" v-model="loading" width="200">
            <div class="text-center white pa-10">
                Loading Document... <v-progress-circular indeterminate size="30"></v-progress-circular>
            </div>
        </v-dialog>
        <v-dialog v-model="showPDFPreview" width="80%">
            <v-card style="position:relative">
                <div class="d-flex align-center justify-space-between pa-4">
                    <v-btn @click="downloadDoc" fab color="primary">
                        <v-icon>fas fa-download</v-icon>
                    </v-btn>
                    <span v-if="currentDoc && currentDoc.DocTitle" class="px-2 text-center">
                        {{ currentDoc.DocTitle }}
                    </span>
                    <v-btn @click="closePreview" fab color="red" right>
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>                    
                </div>

                <PdfPreview :value="previewDocumentUrl" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDocPreview" width="80%">
            <v-card v-if="previewDocumentDocType == 'image'" style="position:relative">
                <v-btn @click="downloadDoc" fab left absolute color="primary">
                    <v-icon>fas fa-download</v-icon>
                </v-btn>
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-img v-if="previewDocumentDocType == 'image'" :src="previewDocumentUrl"></v-img>
            </v-card>
            <v-card v-else-if="previewDocumentDocType == 'download'" class="pa-10" style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <div class="text-center">
                    <strong>No Preview Available</strong><br>
                    <v-btn @click="downloadDoc" color="primary">
                        Download <v-icon right>fas fa-download</v-icon>
                    </v-btn>
                </div>
            </v-card>
            <v-card v-else style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <VueDocPreview :url="previewDocumentUrl" :type="previewDocumentDocType" />
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import VueDocPreview from 'vue-doc-preview'
import PdfPreview from '@/CMS/editor/components/PdfPreview.vue'

export default {
    props: [],
    data() {
        return {
            loading: false,
            showPDFPreview: false,
            showDocPreview: false,
            previewDocumentDocType: null,
            previewDocumentUrl: null,
            currentDoc: null,
        }
    },
    mounted: function() {
        this.getDocument();
    },
    methods: {
        previewDocument: function(doc) {
            if (doc.DocType == 'pdf') {
                this.showPDFPreview = true;
                this.showDocPreview = false;
            } else {
                this.showPDFPreview = false;
                this.showDocPreview = true;
                switch (doc.DocType) {
                    case 'doc':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'docx':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'txt':
                        this.previewDocumentDocType = "text"
                        break;
                    case 'csv':
                        this.previewDocumentDocType = "code"
                        break;
                    case 'jpg':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'png':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'gif':
                        this.previewDocumentDocType = "image"
                        break;
                    default:
                        this.previewDocumentDocType = "download"
                        break;
                }
            }
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                this.previewDocumentUrl = doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL)
                return
            }
            this.previewDocumentUrl = doc.DownloadLink;
        },
        getDocument() {
            var id = this.$route.params.document_id
            var g = this
            this.loading = true
            QuilityAPI.getDocument(id).then(function(json) {
                g.loading = false
                g.currentDoc = json.data;
                g.previewDocument(json.data);

            }).catch(function() {
                g.loading = false
            })
        },
        downloadDoc: function() {
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                window.open(this.currentDoc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL), "_blank");
                return
            }
            window.open(this.currentDoc.DownloadLink, "_blank");
        },
        closePreview: function() {
            this.previewDocumentUrl = ''
            var g = this
            this.$nextTick(function() {
                g.showPDFPreview = false
                g.showDocPreview = false
            })
        },
        viewDoc: function(doc) {
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                window.open(doc.ViewLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL), "_blank")
                return
            }
            window.open(doc.ViewLink, "_blank")
        },
    },
    components: {
        VueDocPreview,
        PdfPreview,
    }
}

</script>
