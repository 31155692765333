<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <!--<q-my-leads-submenu></q-my-leads-submenu>//-->
        <v-row class="mx-5">
            <v-col cols=12>
                <v-card>
                    <q-form-data-table :rows="25" title="Content">
                    </q-form-data-table>
                </v-card>
            </v-col>
        </v-row>
        <div v-if="notice">
            <v-snackbar v-model="notice.visible" top :color="notice.color" multi-line :timeout="6000">
                {{ notice.text }}
                <v-btn text @click="notice.visible = false">
                    Close
                </v-btn>
            </v-snackbar>
        </div>
    </v-container>
</template>
<script>
//table data + search
import QFormDataTable from './components/QFormDataTable.vue';
//import QMyLeadsSubmenu from '@/components/navigation/QMyLeadsSubmenu.vue';


export default {
    data() {
        return {
            search: '',
            notice: null
        }
    },
    computed: {

    },
    components: {
        QFormDataTable,
        //QMyLeadsSubmenu
    }
}
</script>