<template>
    <div>
        <div v-if="questions && questions.length == 0">
            <v-btn block x-large @click="addAt(0)">Add a Question!</v-btn>
        </div>
        <draggable v-else tag="div" :list="questions" class="list-group mt-6" handle=".handle">
            <div class="list-group-item block mb-1 mt-1 mx-1" outlined v-for="(question, idx) in questions" :key="question.key" :cols="question.FormQuestionConfig.cols" :sm="question.FormQuestionConfig.smCols" :md="question.FormQuestionConfig.mdCols" :lg="question.FormQuestionConfig.lgCols">
                <div column>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on">
                                <v-icon small>fa fa-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item @click="addAt(idx)">
                                <v-list-item-title>Add Question Above</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="addAt(idx + 1)">
                                <v-list-item-title>Add Question Below</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="copyAt(idx)">
                                <v-list-item-title>Copy Question</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="pasteAt(idx)">
                                <v-list-item-title>Paste Question Above</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="pasteAt(idx + 1)">
                                <v-list-item-title>Paste Question Below</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="removeAt(idx)">
                                <v-list-item-title>Cut/Remove Question</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="question.ID == 'new'" min-width="500px">
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" v-on="on" height="40px">
                                <v-icon small left>fa fa-cubes</v-icon>{{ getQuestionTypeLabel(question) }}
                            </v-btn>
                        </template>
                        <v-row style="background-color: #fff;">
                            <v-col cols="6">
                                <v-list>
                                    <v-list-item @click="changeQuestionType(idx, type.value)" v-for="(type, key) in available_types" :key="key">
                                        <v-list-item-title>{{ type.label }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="6">
                                <v-list>
                                    <v-list-item @click="changeQuestionType(idx, type.value)" v-for="(type, key) in available_complex_types" :key="key">
                                        <v-list-item-title>{{ type.label }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-menu>
                    <v-menu offset-y v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" v-on="on" height="40px">
                                <v-icon small left>fa fa-cubes</v-icon>{{ getQuestionTypeLabel(question) }}
                            </v-btn>
                        </template>
                    </v-menu>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }" v-if="typeof question.EnabledInd != 'undefined'">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on" @click="toggleQuestionVisible(idx)">
                                <v-icon small :class="publishStatusClass(question.EnabledInd)">{{ question.EnabledInd ? 'fa fa-eye-slash' : 'fa fa-eye' }}</v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on">
                                <v-icon small>fa fa-filter</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-row color="white">
                                    <v-col cols=12 class="pb-0">
                                        <v-radio-group v-model="questions[idx].FormQuestionConfig.permissions.exclude" row dense>
                                            <v-radio label="Include" value="include"></v-radio>
                                            <v-radio label="Exclude" value="exclude"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols=4>
                                        <v-select multiple :items="division_items" v-model="questions[idx].FormQuestionConfig.permissions.division" label="Division"></v-select>
                                    </v-col>
                                    <v-col cols=4>
                                        <v-select multiple :items="role_items" v-model="questions[idx].FormQuestionConfig.permissions.role" label="Roles"></v-select>
                                    </v-col>
                                    <v-col cols=4>
                                        <v-select multiple :items="permission_items" v-model="questions[idx].FormQuestionConfig.permissions.permission" label="Permissions"></v-select>
                                    </v-col>
									<v-col cols=4>
                                        <v-select multiple :items="contract_levels" v-model="questions[idx].FormQuestionConfig.permissions.contractLevel" label="Contract Level"></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <span v-if="collapseBlocks">
                        {{ question.FormQuestionTitle}}
                    </span>
                    <v-btn class="handle " dark color="gray" small fab absolute right>
                        <v-icon small>fas fa-arrows-alt</v-icon>
                    </v-btn>
                </div>
                <div :class="collapsedClasses(['fab-container pa-2 mb-6 block-container'])">
                    <div :is="'fml-' + questions[idx].FormQuestionConfig.type + '-editor'" v-model="questions[idx]"
                         v-on:save="$emit('save')" :ref="'question_' + idx"
                         :form-type="formType"
                    ></div>
                </div>
            </div>
        </draggable>
        <div class=" text-center mt-4" v-if="questions && questions.length > 0">
            <v-btn x-large @click="addAt(questions.length)">
                <v-icon left small>fas fa-plus</v-icon>New Question
            </v-btn>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import FmlTextFieldEditor from './components/editors/FmlTextFieldEditor.vue'
import FmlTextAreaEditor from './components/editors/FmlTextAreaEditor.vue'
import FmlCheckboxEditor from './components/editors/FmlCheckboxEditor.vue'
import FmlSelectEditor from './components/editors/FmlSelectEditor.vue'
import FmlTagsEditor from './components/editors/FmlTagsEditor.vue'
import FmlRadioEditor from './components/editors/FmlRadioEditor.vue'
import FmlSwitchEditor from './components/editors/FmlSwitchEditor.vue'
import FmlAgentLookupEditor from './components/editors/FmlAgentLookupEditor.vue'
import FmlLeadLookupEditor from './components/editors/FmlLeadLookupEditor.vue'
import FmlRepeatableEditor from './components/editors/FmlRepeatableEditor.vue'
import PromotionLevels from '@/store/PromotionLevels.json'

export default {
    name: "QEditQuestions",
    props: ['value', 'random', 'collapseBlocks', 'formType'],
    data() {
        return {
            dragging: false,
            counter: Math.floor(Math.random() * 1000000000),
            available_complex_types: [],
        };
    },
    mounted: function() {
		this.questions.forEach((question) => {
			if (Array.isArray(question.FormQuestionConfig.permissions)) {
				// permissions might be [] by default, we need to convert it to object
				question.FormQuestionConfig.permissions = {}
			}
		})
    },
    computed: {
		contract_levels() {
            return PromotionLevels.map((level) => {
				return level.ContractLevel
			})
        },
        available_types: function() {
            if (this.formType == 'Quiz') {
                return [{
                        label: "Select",
                        value: "select"
                    }, {
                        label: "Radio",
                        value: "radio"
                    }];
            }

            return [{
                    label: "Text Field",
                    value: "text-field"
                }, {
                    label: "Text Area",
                    value: "text-area"
                }, {
                    label: "Checkbox",
                    value: "checkbox"
                }, {
                    label: "Select",
                    value: "select"
                }, {
                    label: "Tags",
                    value: "tags"
                }, {
                    label: "Radio",
                    value: "radio"
                }, {
                    label: "Switch",
                    value: "switch"
                }, {
                    label: "Agent Lookup",
                    value: "agent-lookup"
                }, {
                    label: "Lead Lookup",
                    value: "lead-lookup"
                }, {
                    label: "Repeatable",
                    value: "repeatable"
                }];
        },
        division_items: function() {
            return [
                'All',
                ...this.raw_divisions,
            ]
        },
        role_items: function() {
            return [
                'Any',
                ...this.role_choices,
            ]
        },
        permission_items: function() {
            return [
                'Any',
                ...this.permission_choices,
            ]
        },
        questions: function() {
            return this.value
        }
    },
    methods: {
        removeAt(idx) {
            var question = this.questions[idx];
            localStorage.setItem('copiedBlock', JSON.stringify(question))
            this.questions.splice(idx, 1);
        },
        addAt(idx) {
            this.counter++;
            let defaultType = "text-field";
            if (this.formType === 'Quiz') {
                defaultType = 'select';
            }
            this.questions.splice(idx, 0, {
                ID: "new",
                FormQuestionTitle: "My First Question?",
                FormQuestionContent: null,
                FormQuestionConfig: {
                    key: this.counter,
                    type: defaultType,
                    rules: [],
                    cols: 12,
                    smCols: null,
                    mdCols: null,
                    lgCols: null,
                    label: 'Answer',
                    messages: null,
                    hint: null,
                    prefix: null,
                    prependIcon: null,
                    default: null,
                    permissions: {},
                    useQuestionAsLabel: true
                },
            });

            if (this.formType === 'Quiz') {
                this.updateFieldDefaults(idx);

                this.$refs['question_' + idx][0].question.FormQuestionConfig.rules.push('required');
            }
        },
        copyAt(idx) {
            var block = this.questions[idx]
            //need to reset the question ID...
            localStorage.setItem('copiedBlock', JSON.stringify(block))
        },
        pasteAt(idx) {
            if (localStorage.getItem('copiedBlock') == null)
                return
            var block = JSON.parse(localStorage.getItem('copiedBlock'))
            this.counter++;
            block.id = this.counter
            block.key = Math.random()
            this.questions.splice(idx, 0, block)
        },
        setFocus: function(idx) {
            for (var i = 0; i < this.questions.length; i++) {
                if (idx != i) {
                    this.questions.hasFocus = false;
                }
            }
        },
        changeQuestionType: function(idx, type) {
            //only allow changing if the question doesn't already have an id.
            if (this.questions[idx].ID == 'new' && this.questions[idx].FormQuestionConfig.type != type) {
                this.questions[idx].FormQuestionConfig.type = type;
                if (typeof this.questions[idx].FormQuestionConfig.styles == 'undefined') {
                    this.questions[idx].FormQuestionConfig.styles = [];
                }
                this.updateFieldDefaults(idx);
            }
        },
        'updateFieldDefaults': function(idx) {
            var g = this
            this.$nextTick(function() {
                g.$refs['question_' + idx][0].updateFieldDefaults();
            })
        },
        useMediaFile: function(file) {
            this.mediaCallback(file);
            this.show_media = false;
        },
        useDocumentFile: function(file) {
            this.documentCallback(file.url, file.title);
            this.show_documents = false;
        },
        collapsedClasses: function(classes) {
            if (this.collapseBlocks)
                classes.push('collapsed')
            return classes.join(' ')
        },
        getQuestionTypeLabel(question) {
            return this.titleCase(question.FormQuestionConfig.type.replace('-', ' '));
        },
        formatLabel: function(key) {
            var str = key.replace("_", " ");
            str = str.replace("-", " ");
            return this.titleCase(str);
        },
        toggleQuestionVisible: function(idx) {
            this.questions[idx].EnabledInd = !this.questions[idx].EnabledInd;
        },
        blockIsHidden(block) {
            if (!block || !block.permissions)
                return false
            if (typeof block.permissions.division == 'undefined') {
                return false
            }
            if (block.permissions.division == 'All' || block.permissions.division == null) {
                return false
            }
            if (block.permissions.division == this.user.currentSetDivision) {
                return false
            }
            return true
        }
    },
    watch: {
        // 'page.blocks': {
        //     deep: true,
        //     handler: function(newV, oldV) {
        //         this.hasChanges = true;
        //     }
        // },
    },
    components: {
        draggable,
        FmlTextFieldEditor,
        FmlCheckboxEditor,
        FmlSelectEditor,
        FmlTagsEditor,
        FmlRadioEditor,
        FmlSwitchEditor,
        FmlAgentLookupEditor,
        FmlLeadLookupEditor,
        FmlRepeatableEditor,
        FmlTextAreaEditor
    },
}
</script>
<style scoped>
.block {
    position: relative;
}

.fab-container {
    padding: 15px;
    background-color: #f7f7f7;
    padding-right: 60px;
}

.unpublished-block {
    color: rgba(0, 0, 0, 0.1) !important;
}

.fab-container.collapsed {
    position: relative;
}

.block-container.collapsed {
    display: none;
}
</style>
