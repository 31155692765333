<script>
import editAgentProfile from './editAgentProfiles.vue'

export default {
    extends: editAgentProfile,
    data() {
        return {
            Division: 'Corporate',
        }
    },

}

</script>
