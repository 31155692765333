<template>
    <fml-select :question="value" ref="parent" v-model="default_response_answer">
        <template v-slot:meta>
            <span>
                <v-btn icon @click="show_config = true">
                    <v-icon left>fas fa-edit</v-icon>
                </v-btn>
                <v-dialog v-model="show_config" width="60%">
                    <v-card>
                        <v-card-title>Edit Question</v-card-title>
                        <v-card-text>
                            <v-tabs dark v-model="tab">
                                <v-tab>Options</v-tab>
                                <v-tab>Config</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-row wrap>
                                        <v-col cols="6">
                                            <v-select label="Choices" v-model="value.FormQuestionConfig.select_options.item_collection" :items="['State Names', 'State Abbreviations', 'Custom']"></v-select>
                                            <v-checkbox label="Chips" v-model="value.FormQuestionConfig.select_options.chips"></v-checkbox>
                                            <v-checkbox label="Allow Multiple Selections" v-model="value.FormQuestionConfig.select_options.multiple"></v-checkbox>
                                        </v-col>
                                        <v-col cols="6">
                                            Enter the available values for selection. Put each choice on a single line.
                                            <v-switch :label="value.FormQuestionConfig.select_options.label_value ? 'Label | Value' : 'Value'" v-model="value.FormQuestionConfig.select_options.label_value"></v-switch>
                                            <v-textarea label="Choices" :messages="value.FormQuestionConfig.select_options.label_value ? 'One \'Label | Value\' on each line' : 'One Value on each line'" v-model="value.FormQuestionConfig.select_options.items_config">
                                            </v-textarea>
                                            <div v-if="formType === 'Quiz'" class="mt-3">
                                                <v-select label="Correct Answer" v-model="value.FormQuestionConfig.correctAnswer" :items="value.FormQuestionConfig.select_options.items" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <fml-field-config v-model="value"></fml-field-config>
                                </v-tab-item>
                            </v-tabs-items>
                            <v-card-actions>
                                <v-btn @click="$emit('save')" color="primary">Save</v-btn>
                                <v-btn @click="show_config = false">Close & Save Later</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </span>
        </template>
    </fml-select>
</template>
<script>
import FmlSelect from "@/FML/views/QuestionTypes/FmlSelect.vue";
import FmlEditorMixins from "./FmlEditorMixins.vue";
import StateNames from "@/store/StateAbbreviations";
import StateAbbreviations from "@/store/StateOptions";
export default {
    props: ['value', 'formType'],
    mixins: [FmlEditorMixins],
    data() {
        return {
            show_config: false,
            tab: null,
        }
    },
    created: function() {

        this.resetItems();
    },
    components: {
        FmlSelect,
    },
    methods: {
        'resetItems': function() {
            switch (this.value.FormQuestionConfig.select_options.item_collection) {
                case "Custom":
                    this.value.FormQuestionConfig.select_options.items = this.parseCustomItems();
                    break;
                case "State Names":
                    this.value.FormQuestionConfig.select_options.label_value = true;
                    var mapped_states = [];
                    //map the data to a format the select can understand.
                    Object.keys(StateNames).forEach(function(k) {
                        mapped_states.push({
                            'text': k,
                            'value': StateNames[k],
                        })
                    })
                    this.value.FormQuestionConfig.select_options.items = mapped_states;
                    this.value.FormQuestionConfig.select_options.items_config = this.stringifyCustomItems(mapped_states);
                    break;
                case "State Abbreviations":
                    this.value.FormQuestionConfig.select_options.label_value = false;
                    this.value.FormQuestionConfig.select_options.items = StateAbbreviations;
                    this.value.FormQuestionConfig.select_options.items_config = this.stringifyCustomItems(StateAbbreviations);
                    break;
            }
        },
        'parseCustomItems': function() {
            var newV = this.value.FormQuestionConfig.select_options.items_config;
            var i = newV.split('\n');
            if (!this.value.FormQuestionConfig.select_options.label_value) {
                return i
            }
            var r = [];
            i.forEach(function(item) {
                if (item.split("|").length > 1) {
                    r.push({
                        'text': item.split("|")[0].trim(),
                        'value': item.split("|")[1].trim(),
                    })
                }
            })
            return r;
        },
        'stringifyCustomItems': function(items) {
            if (this.value.FormQuestionConfig.select_options.label_value) {
                var r = [];
                items.forEach(function(i) {
                    r.push(i.text + "|" + i.value);
                })
                return r.join("\n");
            }
            return items.join("\n");

        }
    },
    watch: {
        'value.FormQuestionConfig.select_options.item_collection': function(newV) {
            this.$nextTick(this.resetItems)
        },
        'value.FormQuestionConfig.select_options.items_config': function(newV) {
            this.$nextTick(this.resetItems)
        },
        'value.FormQuestionConfig.select_options.multiple': function(newV) {
            this.$set(this.value.FormQuestionConfig, "AnswerDataType", newV ? "Array" : "String");
            this.$set(this.value.FormQuestionConfig, "default", newV ? "[]" : null);
            this.$nextTick(this.updateAnswerDataType)
        },
        'value.FormQuestionConfig.select_options.label_value': function(newV) {
            this.$nextTick(this.resetItems);
        }
    }
}
</script>
