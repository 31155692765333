<template>
    <v-card>
        <v-card-title>Edit Item</v-card-title>
        <v-card-text>
            <v-progress-linear indeterminate v-if="saving"></v-progress-linear>
            <v-form v-model="valid" ref="form" :disabled="saving">
                <v-row wrap dense>
                    <v-col cols="12" v-if="error">
                        <v-alert type="error">
                            {{ error }}
                        </v-alert>
                    </v-col>
                    <v-col cols="12" v-if="warning">
                        <v-alert type="warning">
                            {{ warning }}
                        </v-alert>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Label" v-model="current_item.label" :rules="[form_rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex">
                        <v-text-field label="Icon" hint="fas fa-anchor" message="Only for top level menu items" v-model="current_item.icon" :append-icon="current_item.icon"></v-text-field>
                        <v-btn small class="ma-2" outlined color="indigo" @click="loadQuilityIcons">
                            Quility Icons
                        </v-btn>
                        <v-btn small class="ma-2" outlined color="indigo" @click="loadFontAwesome">
                            Font Awesome
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox label="Open in a new tab? (use for external links)" v-model="openInNewTab"></v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="openInNewTab">
                        <v-text-field label="External URL" v-model="current_item.href"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field label="URL" v-model="current_item.to"></v-text-field>
                    </v-col>
                    <v-col cols=4>
                        <v-select multiple :items="division_items" v-model="current_item.divisions" label="Division"></v-select>
                    </v-col>
                    <v-col cols=4>
                        <v-select multiple :items="role_items" v-model="current_item.roles" label="Roles"></v-select>
                    </v-col>
                    <v-col cols=4>
                        <v-select multiple :items="permission_items" v-model="current_item.permissions" label="Permissions"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-btn @click="cancel">Cancel</v-btn>
                        <v-btn class="ml-4" color="primary" @click="saveItem">
                            Save
                            <v-progress-circular :disabled="saving" right size="30" v-if="saving" indeterminate></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
export default {
    props: ['item'],
    data() {
        return {
            saving: false,
            openInNewTab: typeof this.item.href != 'undefined' && this.item.href != null ? true : false,
            warning: null,
            error: null,
            valid: false,
            current_item: {
                id: null
            }
        }
    },
    mounted: function() {
        this.resetItem(this.item);
    },
    computed: {
        'role_types': function() {
            return [
                { text: "Staff", value: "Staff" },
                { text: "Executive", value: "Exec" },
            ]
        },
        division_items: function() {
            return [
                ...this.raw_divisions,
            ]
        },
        role_items: function() {
            return [
                'Any',
                ...this.role_choices,
            ]
        },
        permission_items: function() {
            return [
                'Any',
                ...this.permission_choices,
            ]
        },
    },
    methods: {
        saveItem() {
            var g = this
            if (!this.$refs.form.validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.saving = true;
            if (this.current_item.id == null) {
                var method = QuilityAPI.createNavMenuItem(this.current_item)
            } else {
                var method = QuilityAPI.updateNavMenuItem(this.current_item)
            }
            //now process the promise. 
            method.then(function(resp) {
                    if (typeof resp.data == 'undefined') {
                        g.error = resp.msg
                        setTimeout(function() { g.error = null }, 6000)
                    } else {
                        resp.data.ix = g.current_item.ix
                        resp.data.child_ix = g.current_item.child_ix
                        g.$set(g, 'current_item', resp.data);
                        g.$emit('saved', resp.data)
                    }
                    g.saving = false;
                })
                .catch(function(err) {
                    g.error = err.msg
                    setTimeout(function() { g.error = null }, 6000)
                    g.saving = false;
                });
        },
        loadFontAwesome: function() {
            window.open("https://fontawesome.com/icons?d=gallery&s=solid&m=free", "_tab")
        },
        loadQuilityIcons: function() {
            window.open("/fontello-demo", "_tab")
        },
        resetItem: function(newV) {
            var i = {}
            i = Object.assign(i, newV);
            this.$set(this, 'current_item', i);
        },
        cancel: function() {
            this.$emit('close')
        }
    },
    watch: {
        'item': function(newV) {
            this.resetItem(newV);
        },
        'item.to': function() {
            this.item.href = null
        },
        'item.href': function() {
            this.item.to = null
        },
		'openInNewTab'() {
			if (this.openInNewTab) {
				this.current_item.href = this.current_item.to
				this.current_item.to = null
			} else {
				this.current_item.to = this.current_item.href
				this.current_item.href = null
			}
		}
    },
    components: {
        QDivisionSelect
    }
}

</script>
