<template>
    <div>
        <v-card flat>
            <!--</v-card-title>-->
            <!--
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            //-->
            <h2>Audit Table</h2>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options">
                <!--adds view ticket icon -->
                <template v-slot:item.created_at="{ item }">
                    {{ formatDateTime(item.created_at) }}
                </template>
                <template v-slot:item.id="{ item }">
                    <span class="">
                        <v-btn @click="showData(item)">Load</v-btn>
                    </span>
                </template>
            </v-data-table>
            <h2>Version Preview</h2>
            <page-view v-if="page_version" :page="page_version.page_data" id="preview_version"></page-view>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "@/components/datatables/QGenericDataTableMixin.vue"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import PageView from '@/CMS/views/index.vue'

export default {
    name: "QPageAuditDataTable",
    props: ['pageId'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            options: {
                'sortBy': ['created_at'],
                'sortDesc': [true]
            },
            expanded: [],
            page_version : null,
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Date',
                    value: 'created_at',
                    class: "nowrap",
                    sortable: true,
                }, {
                    text: 'Editor',
                    value: 'change_made_by',
                    class: "nowrap",
                    sortable: false,
                    align: "left"
                }, {
                    text: 'Load',
                    value: 'id',
                    sortable: false,
                    align: "center"
                },
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            if (typeof this.pageId == 'undefined' || this.pageId == null){
                return
            }
            var g = this
            this.data_loading = true
            QuilityAPI.getPageAuditHistory(this.pageId).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        showData : function(item){
            this.$set(this, "page_version", item);
            //this.$emit('load', item.page_data)

        }
    },
    watch: {
        'pageId' : function(newV){
            if (newV != null){
                this.$nextTick(this.refreshData);
            }
        }
    },
    components: {PageView}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
