<template>
    <div style="display:inline-block;">
        <v-btn color="q_leads_2" dark @click="newPageDialog = true">
            <v-icon small left>fas fa-plus</v-icon> New Page
        </v-btn>

        <v-dialog v-model="newPageDialog" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <h4 class="pa-4 font-weight-bold">What type of content would you like to create?</h4>

                <v-select :items="pageTypes" v-model="pageType" />

                <v-select v-if="hasCategories" v-model="categoryId" label="Category" item-text="dropdown_category_name" item-value="id" :items="availableCategories" />

				<v-btn color="primary" @click="newPage">
                    Get started!<v-icon small right>fas fa-plus</v-icon>
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Category from '@/store/Models/Category'

export default {
    name: "NewPageButton",
    data: function() { // data internal to component (not available outside)
        return {
			pageType: 'content-page',
			categoryId: 1,
            newPageDialog: false,
            pageTypes: [
                { text: "Content Page", value: 'content-page' },
                { text: "Contest", value: 'contest' },
                { text: "News", value: 'news' },
                { text: "External/Support Resource", value: 'support-resource' }
            ]
        }
    },
    computed: {
		available_categories () {
            return Category.query().orderBy('category', 'asc').get()
        },
        available_content_categories() {
            return Category.query().where('zendesk_category_id', (v) => v === 0).get()
        },
        // available_zendesk_categories() {
        //     return Category.query().where('zendesk_category_id', function(v) {
        //         return v > 0
        //     }).get();
        // },
		availableCategories () {
			if (this.pageType === 'content-page') {
				return this.available_content_categories
			}
			if (this.pageType === 'support-resource') {
				return this.available_categories
			}
			return this.available_categories
		},
		hasCategories () {
			return this.pageType === 'content-page' || this.pageType === 'support-resource'
		},
    },
	watch: {
		newPageDialog () {
			if (this.newPageDialog) {
				this.pageType = 'content-page'
				this.categoryId = 1
			}
		},
	},
    methods: {
        newPage: function() {
            this.$router.push({ name: 'CMSEditor', params: { page_id: 'new', category_id: this.categoryId, page_type: this.pageType } })
        },
    },
}
</script>
