<template>
    <div :question="value" ref="parent" v-model="default_response_answer">
        <span>
            <div class="d-flex align-center">
                <v-btn icon @click="addField">
                    <v-icon left>fas fa-plus</v-icon>
                </v-btn>
                <strong>{{value.FormQuestionTitle}}</strong> [{{value.ID}}]
                <v-spacer />
                <v-btn icon @click="show_config = true">
                    <v-icon left>fas fa-edit</v-icon>
                </v-btn>
            </div>
            <v-row no-wrap>
                <v-col v-for="(field, ix) in value.FormQuestionConfig.fields" :key="field.key + '_' + ix">
                    <v-select 
                        label="Field Type"
                        v-model="value.FormQuestionConfig.fields[ix].question.FormQuestionConfig.type" 
                        :items="available_field_types"
                        @change="changeQuestionType(ix, field.question.FormQuestionConfig.type, field.key)"
                    />
                    <div class="fif-field-key">
                        field_<input v-model="field.key" />
                    </div>
                    <div :is="'fml-' + field.question.FormQuestionConfig.type + '-editor'" v-model="field.question" v-on:save="$emit('save')" :ref="'question_' + field.key"></div>
                </v-col>
            </v-row>
            <v-dialog v-model="show_config" width="60%">
                <v-card>
                    <v-card-title>Edit Question</v-card-title>
                    <v-card-text>
                        <v-tabs dark v-model="tab">
                            <v-tab>Options</v-tab>
                            <v-tab>Config</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-row wrap>
                                    <v-col cols="6">
                                        <v-text-field label="Add Row Button Label" v-model="value.FormQuestionConfig.buttonLabel"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select label="Simplified Value Function" :items="value_function_options" v-model="value.FormQuestionConfig.valueFunction"></v-select>
                                        <v-select v-if="['join', 'sum'].indexOf(value.FormQuestionConfig.valueFunction) > -1" label="Field for Function" :items="field_options" v-model="value.FormQuestionConfig.functionField"></v-select>
                                        <span v-if="value.FormQuestionConfig.valueFunction == 'sum' && value.FormQuestionConfig.functionField">
                                            This will add up all the values for "field_{{value.FormQuestionConfig.functionField}}" and use this for the simplified value of this repeatable field.
                                        </span>
                                        <span v-if="value.FormQuestionConfig.valueFunction == 'join' && value.FormQuestionConfig.functionField">
                                            This join all the values for "field_{{value.FormQuestionConfig.functionField}}", seperated by a comma, use this for the simplified value of this repeatable field.
                                        </span>
                                        <span v-if="value.FormQuestionConfig.valueFunction == 'count' && value.FormQuestionConfig.functionField">
                                            This will count the number of rows and use this for the simplified value of this repeatable field.
                                        </span>
                                        <v-checkbox v-if="'sum' ==value.FormQuestionConfig.valueFunction" label="Format as Currency" v-model="value.FormQuestionConfig.formatAsCurrency"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <fml-field-config v-model="value"></fml-field-config>
                            </v-tab-item>
                        </v-tabs-items>
                        <v-card-actions>
                            <v-btn @click="$emit('save')" color="primary">Save</v-btn>
                            <v-btn @click="show_config = false">Close & Save Later</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </span>
    </div>
</template>
<script>
//import FmlSelect from "@/FML/views/QuestionTypes/FmlSelect.vue";
import FmlEditorMixins from "./FmlEditorMixins.vue";

import FmlTextFieldEditor from './FmlTextFieldEditor.vue'
import FmlCheckboxEditor from './FmlCheckboxEditor.vue'
import FmlSelectEditor from './FmlSelectEditor.vue'
import FmlTagsEditor from './FmlTagsEditor.vue'
import FmlRadioEditor from './FmlRadioEditor.vue'
import FmlSwitchEditor from './FmlSwitchEditor.vue'
import FmlAgentLookupEditor from './FmlAgentLookupEditor.vue'
import FmlLeadLookupEditor from './FmlLeadLookupEditor.vue'

export default {
    props: ['value'],
    mixins: [FmlEditorMixins],
    data() {
        return {
            show_config: false,
            tab: null,
            default_text_field: {
                "key": 0,
                "question": {
                    "FormQuestionTitle": "Repeat Me!",
                    "FormQuestionContent": null,
                    'FormQuestionConfig': {
                        "type": "text-field",
                        "rules": [],
                        "cols": 12,
                        "smCols": null,
                        "mdCols": null,
                        "lgCols": null,
                        "label": "Answer",
                        "messages": null,
                        "hint": null,
                        "prefix": null,
                        "prependIcon": null,
                        "default": null,
                        "permissions": [],
                        "useQuestionAsLabel": true,
                        "AnswerDataType": "String"
                    }
                }
            },
            counter: Math.round(Math.random() * 10000)
        }
    },
    created: function() {

    },
    components: {
        FmlTextFieldEditor,
        FmlCheckboxEditor,
        FmlSelectEditor,
        FmlTagsEditor,
        FmlRadioEditor,
        FmlSwitchEditor,
        FmlAgentLookupEditor,
        FmlLeadLookupEditor,
    },
    methods: {
        updateFieldDefaults: function() {
            this.$set(this.value.FormQuestionConfig, "AnswerDataType", 'JSON')
            this.$set(this.value.FormQuestionConfig, "buttonLabel", "Add New Row");
            this.$set(this.value.FormQuestionConfig, "valueFunction", "None");
            this.$set(this.value.FormQuestionConfig, "functionField", null);
            this.$set(this.value.FormQuestionConfig, "useQuestionAsLabel", false)

            this.counter = this.counter + 1
            var field = JSON.parse(JSON.stringify(this.default_text_field))
            field.key = this.counter
            this.$set(this.value.FormQuestionConfig, "fields", [
                field
            ])
        },
        addField: function() {
            this.counter = this.counter + 1
            var field = JSON.parse(JSON.stringify(this.default_text_field))
            field.key = this.counter
            this.value.FormQuestionConfig.fields.push(field)
        },
        changeQuestionType: function(idx, type, key) {
            this.value.FormQuestionConfig.fields[idx].question.FormQuestionConfig.type = type;
            if (typeof this.value.FormQuestionConfig.fields[idx].question.FormQuestionConfig.styles == 'undefined') {
                this.value.FormQuestionConfig.fields[idx].question.FormQuestionConfig.styles = [];
            }
            this.updateSubFieldDefaults(key);
        },
        'updateSubFieldDefaults': function(key) {
            var g = this
            this.$nextTick(function() {
                g.$refs['question_' + key][0].updateFieldDefaults();
            })
        },
    },
    computed: {
        'available_field_types': function() {
            return [{
                    'text': 'Text Field',
                    'value': 'text-field'
                },
                {
                    'text': 'Select Dropdown',
                    'value': 'select'
                },
                {
                    'text': 'Checkbox',
                    'value': 'checkbox'
                },
                {
                    'text': 'Radio Buttons',
                    'value': 'radio'
                },
                {
                    'text': 'Switch',
                    'value': 'switch'
                },
                {
                    'text': 'Agent Lookup',
                    'value': 'agent-lookup'
                },
                {
                    'text': 'Lead Lookup',
                    'value': 'lead-lookup'
                },
            ]
        },
        'value_function_options': function() {
            return [{
                    'text': 'None',
                    'value': 'null'
                },
                {
                    'text': 'Sum',
                    'value': 'sum'
                },
                {
                    'text': 'Join',
                    'value': 'join'
                },
                {
                    'text': 'Count',
                    'value': 'count'
                }
            ]
        },
        'field_options': function() {
            var r = [];
            this.value.FormQuestionConfig.fields.forEach(function(f) {
                r.push({
                    'text': f.question.FormQuestionTitle + ' : ' + f.question.FormQuestionConfig.label + ' (field_' + f.key + ')',
                    'value': f.key
                })
            })
            return r
        }
    },
    watch: {

    }
}
</script>

<style lang="scss" scoped>
  .fif-field-key, .fif-field-key input {
    font-size: 12px;
    opacity: 0.7;
    margin-top: -16px;
  }
</style>
