<template>
    <fml-agent-lookup :question="value" ref="parent" v-model="default_response_answer">
        <template v-slot:meta>
            <span>
                <v-btn icon @click="show_config = true">
                    <v-icon left>fas fa-edit</v-icon>
                </v-btn>
                <v-dialog v-model="show_config" width="60%">
                    <v-card>
                        <v-card-title>Edit Question</v-card-title>
                        <v-card-text>
                            <v-tabs dark v-model="tab">
                                <v-tab>Options</v-tab>
                                <v-tab>Config</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-row wrap>
                                        <v-col cols="6">
                                            <v-text-field :label="value.FormQuestionConfig.useQuestionAsLabel ? 'Label' : 'Title or Question'" v-model="value.FormQuestionTitle"></v-text-field>
                                            <v-select :items="['Active & Available', 'Active', 'All']" label="Status" v-model="value.FormQuestionConfig.filters.Status"></v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select label="Divsion" v-model="value.FormQuestionConfig.division" :items="[null, 'Field', 'Corporate']"></v-select>
                                            <v-checkbox label="Use this field as the primary agent association?" v-model="value.FormQuestionConfig.primaryAgentField"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <fml-field-config v-model="value"></fml-field-config>
                                </v-tab-item>
                            </v-tabs-items>
                            <v-card-actions>
                                <v-btn @click="$emit('save')" color="primary">Save</v-btn>
                                <v-btn @click="show_config = false">Close & Save Later</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </span>
        </template>
    </fml-agent-lookup>
</template>
<script>
import FmlAgentLookup from "@/FML/views/QuestionTypes/FmlAgentLookup.vue";
import FmlEditorMixins from "./FmlEditorMixins.vue";
export default {
    props: ['value'],
    mixins: [FmlEditorMixins],
    data() {
        return {
            show_config: false,
            tab: null,
        }
    },
    created: function() {

    },
    components: {
        FmlAgentLookup,
    },
    methods: {

    },
    watch: {

    }
}
</script>