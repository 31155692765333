<template>
</template>
<script>
import FmlFieldConfig from "./FmlFieldConfig.vue";
export default {
    props: ['value'],

    data() {
        return {
            show_config: false,
            tab: null,
        }
    },
    created: function() {

    },
    components: {
        FmlFieldConfig
    },
    methods: {
        updateFieldDefaults: function() {
            this.$refs.parent.updateFieldDefaults();
        },
        updateAnswerDataType: function() {
            this.$refs.parent.updateAnswerDataType();
        },
        convertStringToAnswerDataType: function() {
            if (typeof this.value.FormQuestionConfig.default == 'undefined' || this.value.FormQuestionConfig.default == null) {
                return null
            }
            switch (this.value.FormQuestionConfig.AnswerDataType) {
                case 'Boolean':
                    return (this.value.FormQuestionConfig.default === 'true');
                    break;
                case 'Number':
                    return parseFloat(this.value.FormQuestionConfig.default);
                    break;
                case 'Integer':
                    return parseInt(this.value.FormQuestionConfig.default);
                    break;
                case 'JSON':
                    try {
                        return JSON.parse(this.value.FormQuestionConfig.default);
                    } catch (e) {
                        return null;
                    }
                    return JSON.parse(this.value.FormQuestionConfig.default);
                    break;
                case 'Array':
                    try {
                        var r = JSON.parse(this.value.FormQuestionConfig.default);
                        if (Array.isArray(r)) {
                            return r
                        }
                        return Object.values(r);
                    } catch (e) {
                        return null;
                    }
                    break;
                default:
                    return this.value.FormQuestionConfig.default;
                    break;
            }
        }
    },
    computed: {
        'default_response_answer': function() {
            return {
                FormResponseID: null,
                FormQuestionID: null,
                AnswerDataType: this.value.FormQuestionConfig.AnswerDataType,
                AnswerContent: this.convertStringToAnswerDataType()
            }
        },
    }
}
</script>