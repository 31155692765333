<template>
    <fml-switch :question="value" ref="parent" v-model="default_response_answer">
        <template v-slot:meta>
            <span>
                <v-btn icon @click="show_config = true">
                    <v-icon left>fas fa-edit</v-icon>
                </v-btn>
                <v-dialog v-model="show_config" width="60%">
                    <v-card>
                        <v-card-title>Edit Question</v-card-title>
                        <v-card-text>
                            <v-tabs dark v-model="tab">
                                <v-tab>Options</v-tab>
                                <v-tab>Config</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-row wrap>
                                        <v-col cols="12">
                                            <v-text-field :label="value.FormQuestionConfig.useQuestionAsLabel ? 'Label' : 'Title or Question'" v-model="value.FormQuestionTitle"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <fml-field-config v-model="value"></fml-field-config>
                                </v-tab-item>
                            </v-tabs-items>
                            <v-card-actions>
                                <v-btn @click="$emit('save')" color="primary">Save</v-btn>
                                <v-btn @click="show_config = false">Close & Save Later</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </span>
        </template>
    </fml-switch>
</template>
<script>
import FmlSwitch from "@/FML/views/QuestionTypes/FmlSwitch.vue";
import FmlEditorMixins from "./FmlEditorMixins.vue";
export default {
    props: ['value'],
    mixins: [FmlEditorMixins],
    data() {
        return {
            show_config: false,
            tab: null
        }
    },
    created: function() {

    },
    components: {
        FmlSwitch,
    },
    methods: {

    },
}
</script>